<template>
  <div>
    <h1>Gonzaller</h1>
    <div style="position: relative">
      <div
        style="
          position: sticky;
          top: 0px;
          background-color: rgb(242, 242, 242);
          padding-bottom: 8px;
          margin-top: 4px;
          border-bottom: 1px solid #ccc;
          margin-bottom: 8px;
        "
      >
        <v-text-field
          label="Search"
          prepend-inner-icon="mdi-magnify"
          hide-details
          clearable
          class="pa-0"
          v-model="searchText"
        ></v-text-field>
        <div style="text-align: center; font-size: 90%; color: #0070d7">
          <span
            :style="{ fontWeight: cumples ? 'bold' : 'normal', cursor: 'pointer' }"
            @click="cumples = !cumples"
            >Cumpleaños</span
          >
          |
          <span
            :style="{ fontWeight: aniversarios ? 'bold' : 'normal', cursor: 'pointer' }"
            @click="aniversarios = !aniversarios"
            >Bodas</span
          >
          |
          <span
            :style="{ fontWeight: politicos ? 'bold' : 'normal', cursor: 'pointer' }"
            @click="politicos = !politicos"
            >Politicos</span
          >
        </div>
      </div>
      <div>
        <div class="card" v-for="item in filteredList" :key="item.id">
          <b style="font-size: 110%">{{ item.title }}</b>
          <br />
          {{ timeHappened(item) }} {{ timeUntil(item.next) }}
          <br />
          <span style="font-size: 80%; color: #687078"
            >{{ item.date }} | {{ formatDate(item.next) }}</span
          >
        </div>
      </div>

      <br />
      <br />
      <br />
      <span style="font-size: 70%">Contraseña: {{ $store.state.pass }}</span>
      <br />
      <a style="font-size: 70%" href="https://family.gonzaller.es">family.gonzaller.es</a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

// Components
//import HelloWorld from '../components/HelloWorld.vue'

import { nietos } from '@/assets/nietos'
import sharedjs from '@/assets/shared.js'

export default defineComponent({
  name: 'HomeView',

  components: {},
  data() {
    return {
      key: 'value',
      activeFilter: {
        types: [],
      },
      searchText: '',
      cumples: true,
      aniversarios: true,
      politicos: true,
    }
  },

  computed: {
    filters() {
      let x = { types: [], generations: [] }
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i]

        if (!x.types.find((x) => x.value == item.type)) {
          x.types.push({ value: item.type, title: item.type, color: 'red' })
        }

        if (item.generation_level) {
          if (!x.types.includes(item.generation_level)) x.generations.push(item.generation_level)
        }
      }
      return x
    },
    items() {
      let x = []
      for (let i = 0; i < nietos.length; i++) {
        const nieto = nietos[i]

        const d = new Date(nieto.date)

        const noww = new Date()
        const currentYear = noww.getFullYear()

        const today = new Date(currentYear, noww.getMonth(), noww.getDate()) // new Date() includes time, we want a clean zero-time date here
        let next = new Date(currentYear, d.getMonth(), d.getDate())

        if (next < today) next.setFullYear(currentYear + 1)

        nieto.next = next
        x.push(nieto)
      }

      x.sort((a, b) => {
        return a.next - b.next
      })
      return x
    },

    filteredList() {
      let x = []
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i]

        let addThis = true
        if (!this.cumples && item.type == 'bday') {
          addThis = false
        }
        if (!this.aniversarios && item.type == 'anniversary') {
          addThis = false
        }

        if (!this.politicos && item.politic) {
          addThis = false
        }

        if (!this.cumples && item.type == 'bday') {
          addThis = false
        }

        let searchString = item.title + ' ' + item.keywords
        if (this.searchText && !sharedjs.searchMatch(this.searchText, searchString)) {
          addThis = false
        }

        if (addThis) {
          x.push(item)
        }
      }
      return x
    },
  },

  methods: {
    formatDate(date) {
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      let d = date.getDate()

      if (m < 10) m = '0' + m
      if (d < 10) d = '0' + d
      return y + '-' + m + '-' + d
    },
    timeHappened(item) {
      let next = new Date(item.next)
      let original = new Date(item.date)
      let diff = next - original
      let years = Math.round(diff / (1000 * 60 * 60 * 24 * 365))

      let prefix = 'Hace'
      if (item.type == 'bday') {
        if (item.alive) {
          prefix = 'Cumple'
        } else {
          prefix = 'Cumpliría'
        }
      } else if (item.type == 'anniversary') {
        prefix = 'Hacen'
      } else if (item.type == 'passedaway') {
        prefix = 'Se fue al Cielo hace'
      }

      let suffix = years == 1 ? 'año' : 'años'
      if (item.show_age) {
        return prefix + ' ' + years + ' ' + suffix
      } else {
        return prefix
      }
    },
    timeUntil(date1) {
      let date2 = new Date()
      let diff = Math.abs(date2 - date1) / 1000 / 60 / 60 / 24 // diff in days
      diff = Math.ceil(diff)
      //return diff
      if (diff <= 1) {
        return 'hoy'
      } else if (diff < 2) {
        return 'mañana'
      } else if (diff < 40) {
        let d = diff
        return d == 1 ? 'en 1 día' : 'en ' + d + ' días'
      } else {
        let m = Math.floor(diff / 30)

        return m == 1 ? ' en 1 mes' : 'en ' + m + ' meses'
      }
    },
  },
})
</script>

<style>
.card {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
}
</style>
