const nietos = [
  {
    alive: true,
    date: '1989-05-16',
    facebookId: '1070361493',
    generation_level: 2,
    hijo_linked: 'paloma',
    id: 'E1IRAC3NHY',
    image: 'palo_pa.jpg',
    keywords: 'paloma',
    last_name: 'Sopeña',
    parent: 'Paloma & Alex',
    relation: 'nieto',
    row: 1,
    show_age: true,
    title: 'Palo Sopeña',
    type: 'bday',
  },
  {
    alive: true,
    date: '1990-07-17',
    generation_level: 2,
    hijo_linked: 'paloma',
    id: 'A8O68FLWHB',
    image: 'lourdes_pa.jpg',
    last_name: 'Sopeña',
    parent: 'Paloma & Alex',
    relation: 'nieto',
    row: 2,
    show_age: true,
    title: 'Lourdes Sopeña',
    type: 'bday',
  },
  {
    alive: true,
    date: '1991-11-24',
    generation_level: 2,
    hijo_linked: 'paloma',
    id: 'CO7GLLY83M',
    image: 'alex_pa.jpg',
    last_name: 'Sopeña',
    parent: 'Paloma & Alex',
    relation: 'nieto',
    row: 3,
    show_age: true,
    title: 'Alex Sopeña',
    type: 'bday',
  },
  
  {
    alive: true,
    date: '2021-08-05',
    generation_level: 3,
    hijo_linked: 'paloma',
    id: 'CGL200508M',
    image: 'alex_sdl',
    last_name: 'Sopeña Díaz-Laviada',
    parent: 'Alex & Rocío',
    relation: 'bisnieto',
    row: 93,
    show_age: true,
    title: 'Alex Sopeña Díaz-Laviada',
    type: 'bday',
  },
  
  {
    alive: true,
    date: '1993-07-16',
    generation_level: 2,
    hijo_linked: 'paloma',
    id: 'V2JTUVEIPJ',
    image: 'berni_pa.jpg',
    last_name: 'Sopeña',
    parent: 'Paloma & Alex',
    relation: 'nieto',
    row: 4,
    show_age: true,
    title: 'Berni Sopeña',
    type: 'bday',
  },
  {
    alive: true,
    date: '1995-01-16',
    generation_level: 2,
    hijo_linked: 'paloma',
    id: 'G5AIJUG516',
    image: 'fati_pa.jpg',
    keywords: 'Fátima',
    last_name: 'Sopeña',
    parent: 'Paloma & Alex',
    relation: 'nieto',
    row: 5,
    show_age: true,
    title: 'Fati Sopeña',
    type: 'bday',
  },
  {
    alive: true,
    date: '1987-12-28',
    generation_level: 2,
    hijo_linked: 'maria',
    id: 'N4Z4FZYWWQ',
    image: 'fernando_mf.jpg',
    last_name: 'de la Puente',
    parent: 'María & Fernando',
    relation: 'nieto',
    row: 6,
    show_age: true,
    title: 'Fernando de la Puente',
    type: 'bday',
  },
  {
    alive: true,
    date: '1988-11-15',
    generation_level: 2,
    hijo_linked: 'maria',
    id: 'RFIBFX1982',
    image: 'maria_mf.jpg',
    last_name: 'de la Puente',
    parent: 'María & Fernando',
    relation: 'nieto',
    row: 7,
    show_age: true,
    title: 'María de la Puente',
    type: 'bday',
  },
  {
    alive: false,
    date: '1989-06-03',
    generation_level: 2,
    hijo_linked: 'maria',
    id: 'GQTR5Y28L9',
    image: 'fatima_mf.jpg',
    last_name: 'de la Puente',
    parent: 'María & Fernando',
    relation: 'nieto',
    row: 8,
    show_age: true,
    title: 'Fátima de la Puente',
    type: 'bday',
  },
  {
    alive: true,
    date: '1991-07-20',
    generation_level: 2,
    hijo_linked: 'maria',
    id: 'MNMDP6DRVX',
    image: 'blanca_mf.jpg',
    last_name: 'de la Puente',
    parent: 'María & Fernando',
    relation: 'nieto',
    row: 9,
    show_age: true,
    title: 'Blanca de la Puente',
    type: 'bday',
  },
  {
    alive: true,
    date: '1993-06-24',
    generation_level: 2,
    hijo_linked: 'maria',
    id: 'Q7LJCGILG9',
    image: 'perico_mf.jpg',
    keywords: 'pedro',
    last_name: 'de la Puente',
    parent: 'María & Fernando',
    relation: 'nieto',
    row: 10,
    show_age: true,
    title: 'Perico de la Puente',
    type: 'bday',
  },
  {
    alive: true,
    date: '1995-07-11',
    generation_level: 2,
    hijo_linked: 'maria',
    id: 'MTVB646WDA',
    image: 'santi_mf.jpg',
    last_name: 'de la Puente',
    parent: 'María & Fernando',
    relation: 'nieto',
    row: 11,
    show_age: true,
    title: 'Santi de la Puente',
    type: 'bday',
  },
  {
    alive: true,
    date: '1997-08-19',
    generation_level: 2,
    hijo_linked: 'maria',
    id: 'M38EYWX5HT',
    image: 'javi_mf.jpg',
    last_name: 'de la Puente',
    parent: 'María & Fernando',
    relation: 'nieto',
    row: 12,
    show_age: true,
    title: 'Javi de la Puente',
    type: 'bday',
  },
  {
    alive: true,
    date: '2003-12-22',
    generation_level: 2,
    hijo_linked: 'maria',
    id: 'A234LREFHW',
    image: 'josemari_mf.jpg',
    last_name: 'de la Puente',
    parent: 'María & Fernando',
    relation: 'nieto',
    row: 13,
    show_age: true,
    title: 'Josemari de la Puente',
    type: 'bday',
  },
  {
    alive: true,
    date: '1991-08-06',
    generation_level: 2,
    hijo_linked: 'gerardo',
    id: 'CWRZY4JI06',
    image: 'gerardo_gc.jpg',
    last_name: 'González-Aller',
    parent: 'Gerardo & Cuca',
    relation: 'nieto',
    row: 14,
    show_age: true,
    title: 'Gerardo González-Aller',
    type: 'bday',
  },
  {
    alive: true,
    date: '1992-10-18',
    generation_level: 2,
    hijo_linked: 'gerardo',
    id: 'EKJX0VOUDT',
    image: 'santi_gc.jpg',
    last_name: 'González-Aller',
    parent: 'Gerardo & Cuca',
    relation: 'nieto',
    row: 15,
    show_age: true,
    title: 'Santi González-Aller',
    type: 'bday',
  },
  {
    alive: true,
    date: '1993-11-13',
    generation_level: 2,
    hijo_linked: 'gerardo',
    id: 'VIU0HNWMCM',
    image: 'cuca_gc.jpg',
    last_name: 'González-Aller',
    parent: 'Gerardo & Cuca',
    relation: 'nieto',
    row: 16,
    show_age: true,
    title: 'Cuca González-Aller',
    type: 'bday',
  },
  {
    alive: true,
    date: '1996-02-28',
    generation_level: 2,
    hijo_linked: 'gerardo',
    id: 'KL06672LUM',
    image: 'fatima_gc.jpg',
    last_name: 'González-Aller',
    parent: 'Gerardo & Cuca',
    relation: 'nieto',
    row: 17,
    show_age: true,
    title: 'Fátima González-Aller',
    type: 'bday',
  },
  {
    alive: true,
    date: '2003-02-16',
    generation_level: 2,
    hijo_linked: 'gerardo',
    id: 'MMKPKJHXKE',
    image: 'pilar_gc.jpg',
    last_name: 'González-Aller',
    parent: 'Gerardo & Cuca',
    relation: 'nieto',
    row: 18,
    show_age: true,
    title: 'Pilar González-Aller',
    type: 'bday',
  },
  {
    alive: true,
    date: '2004-12-03',
    generation_level: 2,
    hijo_linked: 'gerardo',
    id: 'S5OJY2U5J9',
    image: 'carmen_gc.jpg',
    last_name: 'González-Aller',
    parent: 'Gerardo & Cuca',
    relation: 'nieto',
    row: 19,
    show_age: true,
    title: 'Carmen González-Aller',
    type: 'bday',
  },
  {
    alive: true,
    date: '2006-07-05',
    generation_level: 2,
    hijo_linked: 'gerardo',
    id: 'UL4265VF4S',
    image: 'gonzalo_gc.jpg',
    last_name: 'González-Aller',
    parent: 'Gerardo & Cuca',
    relation: 'nieto',
    row: 20,
    show_age: true,
    title: 'Gonzalo González-Aller',
    type: 'bday',
  },
  {
    alive: true,
    date: '2009-01-17',
    generation_level: 2,
    hijo_linked: 'gerardo',
    id: 'WEWG6BAFAO',
    image: 'edu_gc.jpg',
    last_name: 'González-Aller',
    parent: 'Gerardo & Cuca',
    relation: 'nieto',
    row: 21,
    show_age: true,
    title: 'Edu González-Aller',
    type: 'bday',
  },
  {
    alive: true,
    date: '1994-04-28',
    generation_level: 2,
    hijo_linked: 'carmen',
    id: 'ZWSCHHNC1K',
    image: 'carmen_cm.jpg',
    last_name: 'Gutiérrez',
    parent: 'Carmen & Manolo',
    relation: 'nieto',
    row: 22,
    show_age: true,
    title: 'Carmen Gutiérrez',
    type: 'bday',
  },
  {
    alive: true,
    date: '1995-11-22',
    generation_level: 2,
    hijo_linked: 'carmen',
    id: 'AUMSMC2T82',
    image: 'isabel_cm.jpg',
    last_name: 'Gutiérrez',
    parent: 'Carmen & Manolo',
    relation: 'nieto',
    row: 23,
    show_age: true,
    title: 'Isabel Gutiérrez',
    type: 'bday',
  },
  {
    alive: true,
    date: '1997-06-30',
    generation_level: 2,
    hijo_linked: 'carmen',
    id: 'AGVCKSY61C',
    image: 'manolo_cm.jpg',
    keywords: 'Manuel, Jamón',
    last_name: 'Gutiérrez',
    parent: 'Carmen & Manolo',
    relation: 'nieto',
    row: 24,
    show_age: true,
    title: 'Manolo Gutiérrez',
    type: 'bday',
  },
  {
    alive: true,
    date: '1998-09-23',
    generation_level: 2,
    hijo_linked: 'carmen',
    id: 'FYSE34UZWJ',
    image: 'manena_cm.jpg',
    last_name: 'Gutiérrez',
    parent: 'Carmen & Manolo',
    relation: 'nieto',
    row: 25,
    show_age: true,
    title: 'Manena Gutiérrez',
    type: 'bday',
  },
  {
    alive: true,
    date: '2001-02-11',
    generation_level: 2,
    hijo_linked: 'carmen',
    id: 'Z0OUK6B81B',
    image: 'lourdes_cm.jpg',
    last_name: 'Gutiérrez',
    parent: 'Carmen & Manolo',
    relation: 'nieto',
    row: 26,
    show_age: true,
    title: 'Lourdes Gutiérrez',
    type: 'bday',
  },
  {
    alive: true,
    date: '2004-02-10',
    generation_level: 2,
    hijo_linked: 'carmen',
    id: 'CTJQ27D6TM',
    image: 'paloma_cm.jpg',
    last_name: 'Gutiérrez',
    parent: 'Carmen & Manolo',
    relation: 'nieto',
    row: 27,
    show_age: true,
    title: 'Paloma Gutiérrez',
    type: 'bday',
  },
  {
    alive: true,
    date: '2007-12-14',
    generation_level: 2,
    hijo_linked: 'carmen',
    id: 'Y6ZXHZC8ST',
    image: 'alvaro_cm.jpg',
    last_name: 'Gutiérrez',
    parent: 'Carmen & Manolo',
    relation: 'nieto',
    row: 28,
    show_age: true,
    title: 'Álvaro Gutiérrez',
    type: 'bday',
  },
  {
    alive: true,
    date: '2001-07-30',
    generation_level: 2,
    hijo_linked: 'isabel',
    id: 'INIV9H1122',
    image: 'isa_ia.jpg',
    last_name: 'Ciordia',
    parent: 'Isabel & Alex',
    relation: 'nieto',
    row: 29,
    show_age: true,
    title: 'Isa Ciordia',
    type: 'bday',
  },
  {
    alive: true,
    date: '2003-09-04',
    generation_level: 2,
    hijo_linked: 'isabel',
    id: 'KRR3121R7B',
    image: 'macarena_ia.jpg',
    last_name: 'Ciordia',
    parent: 'Isabel & Alex',
    relation: 'nieto',
    row: 30,
    show_age: true,
    title: 'Macarena Ciordia',
    type: 'bday',
  },
  {
    alive: true,
    date: '2005-05-23',
    generation_level: 2,
    hijo_linked: 'isabel',
    id: 'N1MOGAOV3T',
    image: 'rocio_ia.jpg',
    last_name: 'Ciordia',
    parent: 'Isabel & Alex',
    relation: 'nieto',
    row: 31,
    show_age: true,
    title: 'Rocío Ciordia',
    type: 'bday',
  },
  {
    alive: true,
    date: '2007-03-19',
    generation_level: 2,
    hijo_linked: 'isabel',
    id: 'PLNK225NB8',
    image: 'almudena_ia.jpg',
    last_name: 'Ciordia',
    parent: 'Isabel & Alex',
    relation: 'nieto',
    row: 32,
    show_age: true,
    title: 'Almudena Ciordia',
    type: 'bday',
  },
  {
    alive: true,
    date: '2009-05-20',
    generation_level: 2,
    hijo_linked: 'isabel',
    id: 'ZJUSO89RU5',
    image: 'pipo_ia.jpg',
    keywords: 'Alex',
    last_name: 'Ciordia',
    parent: 'Isabel & Alex',
    relation: 'nieto',
    row: 33,
    show_age: true,
    title: 'Pipo Ciordia',
    type: 'bday',
  },
  {
    alive: true,
    date: '2011-07-27',
    generation_level: 2,
    hijo_linked: 'isabel',
    id: 'X8U9BQBSW7',
    image: 'patricia_ia.jpg',
    last_name: 'Ciordia',
    parent: 'Isabel & Alex',
    relation: 'nieto',
    row: 34,
    show_age: true,
    title: 'Patricia Ciordia',
    type: 'bday',
  },
  {
    alive: true,
    date: '2013-05-08',
    generation_level: 2,
    hijo_linked: 'isabel',
    id: 'TV2SSHC4SM',
    image: 'carmen_ia.jpg',
    last_name: 'Ciordia',
    parent: 'Isabel & Alex',
    relation: 'nieto',
    row: 35,
    show_age: true,
    title: 'Carmen Ciordia',
    type: 'bday',
  },
  {
    alive: true,
    date: '2015-04-28',
    generation_level: 2,
    hijo_linked: 'isabel',
    id: 'ZGTG3B3LYB',
    image: 'fatima_ia.jpg',
    last_name: 'Ciordia',
    parent: 'Isabel & Alex',
    relation: 'nieto',
    row: 36,
    show_age: true,
    title: 'Fátima Ciordia',
    type: 'bday',
  },
  {
    alive: true,
    date: '1961-10-08',
    generation_level: 1,
    hijo_linked: 'santi',
    id: 'EYOH8JUIGT',
    image: 'santi_sm.jpg',
    last_name: 'González-Aller',
    parent: 'Santi & Manena',
    relation: 'hijo',
    row: 37,
    show_age: true,
    title: 'Santi González-Aller',
    type: 'bday',
  },
  {
    alive: true,
    date: '1963-01-14',
    generation_level: 1,
    hijo_linked: 'geran',
    id: 'YNW0UA2936',
    image: 'gerardo_sm.jpg',
    last_name: 'González-Aller',
    parent: 'Santi & Manena',
    relation: 'hijo',
    row: 38,
    show_age: true,
    title: 'Gerardo González-Aller',
    type: 'bday',
  },
  {
    alive: true,
    date: '1964-05-13',
    generation_level: 1,
    hijo_linked: 'maria',
    id: 'T9B5B9424F',
    image: 'maria_sm.jpg',
    last_name: 'González-Aller',
    parent: 'Santi & Manena',
    relation: 'hijo',
    row: 39,
    show_age: true,
    title: 'María González-Aller',
    type: 'bday',
  },
  {
    alive: true,
    date: '1965-11-17',
    generation_level: 1,
    hijo_linked: 'paloma',
    id: 'EI29CKRJNK',
    image: 'paloma_sm.jpg',
    last_name: 'González-Aller',
    parent: 'Santi & Manena',
    relation: 'hijo',
    row: 40,
    show_age: true,
    title: 'Paloma González-Aller',
    type: 'bday',
  },
  {
    alive: true,
    date: '1969-04-29',
    generation_level: 1,
    hijo_linked: 'carmen',
    id: 'YSECEXAVQE',
    image: 'carmen_sm.jpg',
    last_name: 'González-Aller',
    parent: 'Santi & Manena',
    relation: 'hijo',
    row: 41,
    show_age: true,
    title: 'Carmen González-Aller',
    type: 'bday',
  },
  {
    alive: true,
    date: '1975-11-11',
    generation_level: 1,
    hijo_linked: 'isabel',
    id: 'H479QFDK4P',
    image: 'isabel_sm.jpg',
    last_name: 'González-Aller',
    parent: 'Santi & Manena',
    relation: 'hijo',
    row: 42,
    show_age: true,
    title: 'Isabel González-Aller',
    type: 'bday',
  },
  {
    alive: true,
    date: '1971-07-09',
    generation_level: 1,
    hijo_linked: 'gerardo',
    id: 'ZUGSN2GT85',
    image: 'cuca_g.jpg',
    parent: 'nuero',
    relation: 'nuero',
    row: 43,
    show_age: false,
    title: 'Cuca',
    type: 'bday',
  },
  {
    alive: true,
    date: '1963-03-05',
    generation_level: 1,
    hijo_linked: 'maria',
    id: 'GCPVC8CN77',
    image: 'fernando_m.jpg',
    last_name: 'de la Puente',
    parent: 'nuero',
    relation: 'nuero',
    row: 44,
    show_age: true,
    title: 'Fernando de la Puente',
    type: 'bday',
  },
  {
    alive: true,
    date: '1960-08-25',
    generation_level: 1,
    hijo_linked: 'paloma',
    id: 'QP94KTIFDK',
    image: 'alex_p.jpg',
    last_name: 'Sopeña',
    parent: 'nuero',
    relation: 'nuero',
    row: 45,
    show_age: true,
    title: 'Alex Sopeña',
    type: 'bday',
  },
  {
    alive: true,
    date: '1965-11-20',
    generation_level: 1,
    hijo_linked: 'carmen',
    id: 'RPTDPCTBT7',
    image: 'manolo_c.jpg',
    keywords: 'Manuel',
    last_name: 'Gutiérrez',
    parent: 'nuero',
    relation: 'nuero',
    row: 46,
    show_age: true,
    title: 'Manolo Gutiérrez',
    type: 'bday',
  },
  {
    alive: true,
    date: '1973-02-05',
    generation_level: 1,
    hijo_linked: 'isabel',
    id: 'UHQWR8IYSY',
    image: 'alex_i.jpg',
    last_name: 'Ciordia',
    parent: 'nuero',
    relation: 'nuero',
    row: 47,
    show_age: true,
    title: 'Alex Ciordia',
    type: 'bday',
  },
  {
    alive: true,
    date: '1934-10-09',
    generation_level: 0,
    hijo_linked: 'abuelos',
    id: 'VUSSU4LRX2',
    image: 'abuelo.jpg',
    keywords: 'santi',
    last_name: 'González-Aller',
    parent: 'zero',
    row: 48,
    show_age: true,
    title: 'Abuelo',
    type: 'bday',
  },
  {
    alive: false,
    date: '1936-02-02',
    generation_level: 0,
    hijo_linked: 'abuelos',
    id: 'KX8AO7SGJZ',
    image: 'abuela.jpg',
    keywords: 'manena',
    last_name: 'Yáñez',
    parent: 'zero',
    row: 49,
    show_age: true,
    title: 'Abuela',
    type: 'bday',
  },
  {
    alive: true,
    date: '1987-09-07',
    generation_level: 1,
    id: 'EB5MWG7A7W',
    image: 'paloma_alex.jpg',
    keywords: 'boda',
    last_name: 'Sopeña',
    parent: 'nuero',
    relation: 'boda',
    row: 50,
    show_age: true,
    title: 'Aniversario Paloma & Alex',
    type: 'anniversary',
  },
  {
    alive: true,
    date: '1987-03-14',
    generation_level: 1,
    id: 'RV5VEPUUTB',
    image: 'maria_fernando.jpg',
    keywords: 'boda',
    last_name: 'de la Puente',
    parent: 'nuero',
    relation: 'boda',
    row: 51,
    show_age: true,
    title: 'Aniversario María & Fernando',
    type: 'anniversary',
  },
  {
    alive: true,
    date: '1990-10-12',
    generation_level: 1,
    id: 'MVRFS61R6R',
    image: 'gerardo_cuca.jpg',
    keywords: 'boda',
    last_name: 'González-Aller',
    parent: 'nuero',
    relation: 'boda',
    row: 52,
    show_age: true,
    title: 'Aniversario Gerardo & Cuca',
    type: 'anniversary',
  },
  {
    alive: true,
    date: '1993-02-13',
    generation_level: 1,
    id: 'GX0ESBE4RK',
    image: 'carmen_manolo.jpg',
    keywords: 'boda, Manuel',
    last_name: 'Gutiérrez',
    parent: 'nuero',
    relation: 'boda',
    row: 53,
    show_age: true,
    title: 'Aniversario Carmen & Manolo',
    type: 'anniversary',
  },
  {
    alive: true,
    date: '2000-03-11',
    generation_level: 1,
    id: 'DSAMQRJN0G',
    image: 'isabel_alex.jpg',
    keywords: 'boda',
    last_name: 'Ciordia',
    parent: 'nuero',
    relation: 'boda',
    row: 54,
    show_age: true,
    title: 'Aniversario Isabel & Alex',
    type: 'anniversary',
  },
  {
    alive: true,
    date: '1960-08-26',
    generation_level: 1,
    id: 'MRWJEACXAM',
    image: 'abuelo_abuela.jpg',
    keywords: 'santi, manena, boda',
    last_name: 'González-Aller',
    parent: 'nuero',
    relation: 'boda',
    row: 55,
    show_age: true,
    title: 'Aniversario Abuelos',
    type: 'anniversary',
  },
  {
    alive: true,
    date: '2020-11-16',
    generation_level: 0,
    hijo_linked: 'abuelos',
    id: 'MTIKEENASS',
    keywords: 'manena, abuela',
    parent: 'zero',
    row: 56,
    show_age: true,
    title: 'Abuelita al Cielo',
    type: 'passedaway',
  },
  {
    alive: true,
    date: '1992-01-13',
    generation_level: 2,
    hijo_linked: 'paloma',
    id: 'SAV6KZFQMI',
    image: 'rocio_dlm.jpg',
    last_name: 'Díaz-Laviada Mesa',
    parent: 'Paloma & Alex',
    relation: 'nuero',
    row: 57,
    show_age: true,
    title: 'Rocío Díaz-Laviada',
    type: 'bday',
    politic: true,
  },
  {
    alive: true,
    date: '1989-05-08',
    generation_level: 2,
    hijo_linked: 'paloma',
    id: 'HBU8D5HC8Z',
    image: 'juan_dlm.jpg',
    last_name: 'Díaz-Laviada Mesa',
    parent: 'Paloma & Alex',
    relation: 'nuero',
    row: 58,
    show_age: true,
    title: 'Juan Díaz-Laviada',
    type: 'bday',
    politic: true,
  },
  {
    alive: true,
    date: '2020-02-22',
    generation_level: 2,
    hijo_linked: 'paloma',
    id: 'R70EKJY3B5',
    image: 'palo_juan_dls.jpg',
    keywords: 'boda',
    last_name: 'Díaz-Laviada Sopeña',
    parent: 'Paloma & Alex',
    relation: 'boda',
    row: 59,
    show_age: true,
    title: 'Aniversario Paloma y Juan',
    type: 'anniversary',
  },
  {
    alive: true,
    date: '2020-07-18',
    generation_level: 2,
    hijo_linked: 'paloma',
    id: 'U8EJHSUN8R',
    image: 'alex_rocio.sdl.jpg',
    keywords: 'boda',
    last_name: 'Sopeña Díaz-Laviada',
    parent: 'Paloma & Alex',
    relation: 'boda',
    row: 60,
    show_age: true,
    title: 'Aniversario Alex y Rocío',
    type: 'anniversary',
  },
]

module.exports = { nietos }
