import { createStore } from 'vuex'

export default createStore({
  state: {
    pass: '',
  },
  getters: {},
  mutations: {
    setPass(state, pass) {
      state.pass = pass
      localStorage.pass = pass
    },
  },
  actions: {},
  modules: {},
})
