<template>
  <div class="wrapper">
    <div class="content">
      <h1>Gonzaller</h1>
      <p>Esta applicación require contraseña</p>
      <v-text-field
        type="text"
        v-model="password"
        clearable
        hide-details
        @keypress.enter="authenticate"
      ></v-text-field>
      <span style="font-size: 75%"><b>Pista:</b> chocolatito</span>
      <br />
      <br />
      <v-btn @click="authenticate" flat block color="success">Submit</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: '',
      type: 'password',
    }
  },
  methods: {
    authenticate() {
      let p = this.password.toLowerCase()
      this.$store.commit('setPass', p)
      this.$router.push('/')
    },
  },
  mounted() {
    this.password = this.$store.state.pass
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding-top: 50%;
}
.content {
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
}
</style>
