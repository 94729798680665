<template>
  <v-app>
    <v-main class="main">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data() {
    return {
      key: 'value',
    }
  },
  mounted() {
    let p = localStorage.pass
    if (!p) p = ''
    this.$store.commit('setPass', p) //load the password from the localStorage
  },
}
</script>

<style>
body {
  background-color: rgb(242, 242, 242);
}
.main {
  margin: auto;
  padding: 1rem !important;
  width: 600px;
  background-color: rgb(242, 242, 242);
}
.v-application {
  background-color: rgb(242, 242, 242);
}
</style>
